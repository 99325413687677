<template>
  <div>
    <div id="contract-manage">
      <div class="filter-panel">
        <input
            v-model="queryParams.keyword"
            type="text"
            placeholder="搜索合同编号/租客/房间"
            class="cs-input"
            style="margin: 0; margin-bottom: 15px"
        />
        <CSSelect style="margin-left: 20px">
          <select v-model="queryParams.state">
            <option value="">合同状态不限</option>
            <option
                v-for="(name, state) in contractStates"
                :key="state"
                :value="state"
            >
              {{ name }}
            </option>
          </select>
        </CSSelect>
        <button
            type="button"
            class="btn btn-primary"
            @click="getContractList()"
            style="margin-left: 10px"
        >
          查询
        </button>
      </div>

      <div class="result-panel">
        <CSTable :thead-top="filterHeight">
          <template v-slot:header>
            <div class="table-header-panel text-right">
              <p style="float: left; margin-top: 4px">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-menua-zu92"></use>
                </svg
                >
                <span
                    style="
                    font-weight: 400;
                    line-height: 20px;
                    color: #999999;
                    margin-left: 5px;
                  "
                >自动到期或终止合同后，租客剩余水/电费预存金将转给下一个租赁房间的租客。</span
                >
              </p>

              <button
                  type="button"
                  class="btn btn-primary sticky-right btn-sm"
                  @click="createContract(true)"
              >
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                新建合同
              </button>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>创建时间</th>
              <th>合同编号</th>
              <th>合同状态</th>
              <th>合同期限</th>
              <th>租客</th>
              <th>房间</th>
              <th>房间租金</th>
              <th>房间押金</th>
              <th>其它费用</th>
              <th>附件</th>
              <th>创建人/负责人</th>
              <th>操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="contract in contractList" :key="contract.id">
              <td class="date-td">
                {{ contract.createTime }}
              </td>
              <td class="td_two">
                <template v-if="contract.type != ContractType.CREATE">
                </template>
                <div @click="lookContractDetail(contract.id)">
                  <span class="allow-click" style=" text-align: center;">{{ contract.contractNo }}</span>
                  <div
                      style="width: 40px; font-size: 1px; padding: 0 6px;"
                      :class="[
                      'cs-tag',
                      contract.type == ContractType.PRE_RENEW
                        ? 'success'
                        : 'warning',
                    ]"
                      class="change_contract"
                      v-if="contractTypeStr[contract.type]"
                  >
                    {{ contractTypeStr[contract.type] }}
                  </div>
                </div>
              </td>
              <td>
                {{ contractStates[contract.state] }}
              </td>
              <td style="min-width: 180px">
                <template
                    v-if="
                    getSurplusDays(
                      contract.contractPeriod.split('~')[1].trim()
                    ) <= 15 &&
                    getSurplusDays(
                      contract.contractPeriod.split('~')[1].trim()
                    ) >= 0
                  "
                >
                  <div
                      class="cs-tag error"
                      style="min-width: 120px; padding: 2px 5px; max-width: 220px"
                  >
                    距离合同截止还剩
                    {{
                      getSurplusDays(
                          contract.contractPeriod.split("~")[1].trim()
                      )
                    }}
                    天
                  </div>
                </template>
                <p class="mt-1 margin_">
                  {{ contract.contractPeriod }}
                </p>
              </td>
              <td>
                <div>
                  {{ contract.lesseeCompany }}
                </div>
                <div>
                  {{ contract.lesseeName }}
                  【{{ contract.lesseePhone }}】
                </div>
              </td>
              <td>
                <div>
                  <p
                      v-for="(room, index) in contract.rooms"
                      :key="`${room.roomName}_${index}`"
                      class="padding_none"
                  >
                    {{ room.buildingName }}-{{ room.roomName }}
                  </p>
                </div>
              </td>
              <td>{{ contract.rent }}元</td>
              <td>{{ contract.deposit }}元</td>
              <td>
                <template
                    v-if="contract.otherCharges && contract.otherCharges != ''"
                >
                  <div
                      class="btn btn-link"
                      style="padding-left: 15px"
                      @click="lookContractOrtherCharge(contract.otherCharges)"
                  >
                    查看
                  </div>
                </template>
                <template v-else>-</template>
              </td>
              <td>
                <template
                    v-if="contract.attachments && contract.attachments != ''"
                >
                  <div
                      class="btn btn-link"
                      style="padding-left: 11px"
                      @click="lookContractAttachments(contract.attachments)"
                  >
                    查看
                  </div>
                </template>
                <template v-else>-</template>
              </td>
              <td>
                <div>{{ contract.creator }}</div>
                <div>{{ contract.principal }}</div>
              </td>
              <td>
                <div class="btn-group">
                  <button
                      type="button"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu" style="width: 100px">
                    <li>
                      <a
                          @click="
                          changeOperateContract(
                            ContractType.PRE_RENEW,
                            contract
                          )
                        "
                          style="width: 100%"
                      >
                        续签合同
                      </a>
                    </li>
                    <li v-if="contract.state != 3">
                      <a
                          @click="
                          changeOperateContract(
                            ContractType.PRE_UPDATE,
                            contract
                          )
                        "
                          style="width: 100%"
                      >
                        变更合同
                      </a>
                    </li>
                    <li>
                      <a
                          @click="terminateContract(contract.id)"
                          style="width: 100%"
                      >
                        终止合同
                      </a>
                    </li>
                    <li>
                      <a
                          @click="cancelContract(contract.id)"
                          style="width: 100%"
                      >删除合同
                      </a>
                    </li>
                    <li>
                      <a
                          @click="showTransferContractModal(contract.id)"
                          style="width: 100%"
                      >转移合同</a
                      >
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </CSTable>
        <div>
          <!-- 分页 -->
          <Pagination name="pagination" componentName="Pagination"></Pagination>
        </div>
      </div>

      <!-- 编辑合同信息 -->
      <OperateContractModal
          :modal-status="operateContractModal.visible"
          :contract-type="operateContractModal.contractType"
          :contract-id="operateContractModal.contractId"
          :style="{
          '--old-contract':
            operateContractModal.contractType != ContractType.CREATE
              ? '150px'
              : '0px',
          '--close-top':
            operateContractModal.contractType != ContractType.CREATE
              ? '-125px'
              : '0px',
        }"
          @change-modal-status="changeOperateContractModalShowStatus"
          @reset-data="getContractList"
      >
        <template
            v-slot:oldContractNo
            v-if="operateContractModal.contractType != ContractType.CREATE"
        >
          <div class="old-contract-info">

            <span style="font-size:30px;font-weight: 600">{{
                contractTypeStr[operateContractModal.contractType]
              }}合同</span>
            <span style="margin-left: 30px;font-size: 30px">
              <span>旧合同编号</span>
              <span>{{ operateContractModal.contractNo }}</span>
            </span>
          </div>
        </template>
      </OperateContractModal>

      <ViewModal></ViewModal>

      <!-- 合同详情 -->
      <el-drawer
          title=""
          size="800px"
          :visible.sync="contractDetailModal.visible"
          direction="rtl"
      >
        <ContractPreview
            style="height: 100%"
            :contract-info="contractDetailModal.contractDeatil"
            @refreshContractData="lookContractDetail"
            :readonly="contractDetailModal.readonly"
        />
      </el-drawer>

      <!-- 转移弹窗 -->
      <CSDialog
          dialogTitle="转移合同"
          dialogWidth="480px"
          :dialogVisible="transferContractModal.visible"
          @onConfirm="confirmTransferContract"
          @onClose="transferContractModal.visible = false"
      >
        <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px">
          <div class="transfer">
            <span style="display: inline-block; margin-right: 40px"
            >负责人</span
            >
            <CSSelect iWidth="36px" height="40px">
              <select v-model="transferToId" style="width: 150px; height: 40px ;"
                      :style="transferToId === ''?'color:#999999 !important;':'color:#000000 !important;'">
                <option value="" style="height: 100%">请选择</option>
                <option
                    v-for="staff in staffList"
                    :key="staff.id"
                    :value="staff.id"
                    style="color: #000000 !important;"
                >
                  {{ staff.name }}
                </option>
              </select>
            </CSSelect>
          </div>
        </div>
      </CSDialog>

      <CSDialog
          dialogTitle="提示"
          dialog-header-class=" "
          dialogWidth="680px"
          dialog-confirm-btn-text="确定"
          @onConfirm="terminateOrCancelContractModal.onOk"
          @onClose="terminateOrCancelContractModal.onCancel"
          :dialogVisible="terminateOrCancelContractModal.visible"
          dialog-header-icon="icon-menua-zu13"
      >
        <div
            slot="dialog-content"
            style="padding: 20px 30px; font-size: 24px; text-align: center"
        >
          <div class="titleHand">
            {{ terminateOrCancelContractModal.title }}
          </div>
          <div class="container-so" style="width: 520px">
            <div class="container-item">
              <div class="container-itemr">合同开始时间</div>
              <div class="container-timef">
                {{ terminationContractList.startDate }}
              </div>
            </div>

            <div class="container-item">
              <div class="container-itemr">合同截止时间</div>
              <div class="container-timef">
                {{ terminationContractList.endDate }}
              </div>
            </div>

            <div class="container-item">
              <div class="container-itemr">房屋租金</div>
              <div class="container-timef">
                {{ terminationContractList.rent }}元
              </div>
            </div>

            <div class="container-item">
              <div class="container-itemr">房屋押金</div>
              <div class="container-timef">
                {{ terminationContractList.deposit }}元
              </div>
            </div>
            <template v-if="terminationContractList.otherCharges">
              <div
                  class="container-item"
                  v-for="(item, index) in terminationContractList.otherCharges"
                  :key="index"
              >
                <div
                    class="container-itemr"
                    style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 30px;
                  "
                >
                  {{ item.name }}
                </div>
                <div class="container-timef">
                  {{
                    item.installments[0].amount
                        ? item.installments[0].amount
                        : 0
                  }}元
                </div>
              </div>
            </template>
            <div class="container-item">
              <div class="container-itemr">房间</div>
              <div class="container-timef">
                {{ terminationContractList.roomsInfo }}
              </div>
            </div>
            <template v-if="terminationContractList.water">
              <div
                  class="container-item"
                  v-for="item in terminationContractList.water"
                  :key="item.id"
              >
                <div class="container-itemr">水表{{ item.no }}</div>
                <div class="container-timef">
                  剩余预存金额：{{
                    item.price < 0
                        ? "欠" + item.price.toString().slice(1)
                        : item.price
                  }}
                </div>
              </div>
            </template>
            <template v-else>
              <div class="container-item">
                <div class="container-itemr">水表【-】</div>
                <div class="container-timef">剩余预存金额：-</div>
              </div>
            </template>
            <template v-if="terminationContractList.electricity">
              <div
                  class="container-item"
                  v-for="item in terminationContractList.electricity"
                  :key="item.id"
              >
                <div class="container-itemr">电表{{ item.no }}</div>
                <div class="container-timef">
                  剩余预存金额：{{
                    item.price < 0
                        ? "欠" + item.price.toString().slice(1)
                        : item.price
                  }}
                </div>
              </div>
            </template>
            <div class="container-item" v-else>
              <div class="container-itemr">电表【-】</div>
              <div class="container-timef">剩余预存金额：-</div>
            </div>
          </div>
          <div style="margin-left: -69px;margin-top: -5px">
            <svg class="icon icon-size" aria-hidden="true">
              <use xlink:href="#icon-menua-zu92"></use>
            </svg
            >
            <span
                style="
              width: 371px;
              height: 40px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              line-height: 20px;
              color: #999999;
              opacity: 1;
            "
            >终止合同后，租客剩余水/电费预存金将转给下一个租赁房间的租客。</span
            >
          </div>
        </div>
      </CSDialog>
    </div>
    <ViewImageModal/>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
import OperateContractModal from "@/components/OperateContractModal.vue";
import ViewModal from "@/components/ViewModal.vue";
import ContractPreview from "@/components/ContractPreview.vue";
import CSSelect from "@/components/common/CSSelect";
import {
  queryContractListUrl,
  transferContractUrl,
  cancelContractUrl,
  terminateContractUrl,
  queryDepartmentStaffUrl,
  queryContractDetailUrl,
  terminationContractUrl, preRenewContractUrl,
} from "@/requestUrl";
import {ContractType} from "../../common/commonConst";
import dayjs from "dayjs";
import CSDialog from "@/components/common/CSDialog";
import {STORAGE_KEY} from "@/constant";
import ViewImageModal from "@/components/ViewImageModal";
import CSTable from "@/components/common/CSTable";

const ContractOperationType = {
  CANCEL: 1,
  TERMINATE: 2,
};

export default {
  props: {},
  components: {
    CSTable,
    ViewImageModal,
    Pagination,
    OperateContractModal,
    ViewModal,
    ContractPreview,
    CSSelect,
    CSDialog,
  },
  data() {
    return {
      filterHeight: 0,
      terminationContractList: {},
      terminateOrCancelContractModal: {
        visible: false,
        title: "",
        onCancel: () => {
          this.terminateOrCancelContractModal.visible = false;
        },
        onOk: () => {
        },
      },
      transferToId: "", // 接受合同的员工id
      staffList: [],
      contractTypeStr: {
        [ContractType.CREATE]: "",
        [ContractType.PRE_RENEW]: "续签",
        [ContractType.PRE_UPDATE]: "变更",
      },
      ContractType: ContractType,
      contractDetailModal: {
        visible: false,
        contractId: null,
        contractDeatil: null,
        readonly: false,
      },
      operateContractModal: {
        contractType: ContractType.CREATE,
        contractNo: null,
        visible: false,
        contractId: null,
      },
      transferContractModal: {
        visible: false,
        contractId: null,
      },
      contractStates: {
        1: "签订",
        2: "执行中",
        3: "已到期",
        4: "已解约",
        5: "已作废",
      },
      queryParams: {
        keyword: "",
        state: "",
      },
      contractList: [],
      operateContractModalShowStatus: false,
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },

  mounted() {
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
  },
  async created() {
    window.addEventListener("keydown", this.getContractListDown);

    const currentStaff = this.$vc.getCurrentStaffInfo();
    //  1004是客服部门
    if (currentStaff.dutyType === "1004" || currentStaff.dutyType === "1010") {
      await this.getStaffList(1, currentStaff.orgId);
    }
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.pageParams.pageNo = pageNo;
      this.getContractList(pageNo);
    });
    this.getContractList();
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.getContractListDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.getContractListDown);
  },
  methods: {
    getContractListDown(e) {
      if (e.keyCode == 13) {
        this.getContractList();
      }
    },
    // 获取合同的剩余天数
    getSurplusDays(date) {
      const diffVal = dayjs(date).valueOf() - dayjs().valueOf();
      return parseInt(diffVal / 1000 / 60 / 60 / 24);
    },
    /**
     * 是否在当前时间之前
     * @param {Date} date 比较的时间
     * @param {Number} num 比较时间增加多少时间
     * @param {String} dateUnit 增加的时间的单位
     *  */
    isAroundDate(date, num, dateUnit) {
      return (
          dayjs()
              .add(num, dateUnit)
              .isAfter(dayjs(date).add(-num, dateUnit), "day") &&
          dayjs().add(num, dateUnit).isBefore(dayjs(date), "day")
      );
    },
    // 查询合同详情
    getContractDetail(contractId) {
      return new Promise((resolve) => {
        this.$fly
            .post(queryContractDetailUrl, {
              contractId: contractId,
              operatorId: this.$vc.getCurrentStaffInfo().userId,
            })
            .then((res) => {
              this.$set(this.contractDetailModal, "contractDeatil", res.data);
              resolve(res);
            });
      });
    },
    /**
     * 查看合同详情
     * @param {Number} contractId 合同id
     *  */
    async lookContractDetail(contractId) {
      const res = await this.getContractDetail(contractId);
      if (res.code != 0) {
        return;
      }
      console.log(res.data);
      this.contractDetailModal = {
        ...this.contractDetailModal,
        visible: true,
        contractId,
        readonly: false,
      };
    },
    // 创建新合同
    createContract() {
      this.operateContractModal = {
        visible: true,
        contractType: ContractType.CREATE,
        contractNo: "",
        contractId: null,
      };
    },
    /**
     * 修改合同操作(续签或变更)
     * @param {Number} operateType 操作类型  ContractType
     * @param {Object} contractInfo 合同信息
     *  */
    async changeOperateContract(operateType, contractInfo) {
      console.log("id=======", contractInfo)
      let res = await this.$fly
          .post(preRenewContractUrl, {
            contractId: contractInfo.id,
            operatorId: this.$vc.getCurrentStaffInfo().userId,
          })
      if (res.code != 0) {
        return;
      }
      this.operateContractModal = {
        visible: true,
        contractType: operateType,
        contractNo: contractInfo.contractNo,
        contractId: contractInfo.id,
      };
      // operateContractModal.visible
    },
    /**
     * 下载文件（仅限阿里oss的文件）
     * @param {String} fileSrc 文件的资源地址
     *  */
    downloadFile(fileSrc) {
      window.open(
          fileSrc.replace(
              "https://alioss.techops.cn/",
              "https://cosmoss-img.oss-cn-beijing.aliyuncs.com/"
          )
      );
    },
    /**
     * 查看合同附件
     * @param {Array} attachments 其他费用
     *  */
    lookContractAttachments(attachments) {
      sessionStorage.setItem(
          STORAGE_KEY.CONTRACT_ATTACHMENTS,
          JSON.stringify(attachments)
      );
      this.$router.push({name: "Enclosure"});
    },
    /**
     * 查看其它收费详情
     * @param {Array} charges 其他费用
     *  */
    lookContractOrtherCharge(charges) {
      this.$router.push({
        name: "OtherExpenses",
        query: {charges: JSON.stringify(charges)},
      });
    },
    /**
     * 获取员工列表
     * @param {Number} page 查询员工的位置
     * @param {String} orgId 要查询的部门id
     *  */
    getStaffList(page = 1, orgId) {
      const currentStaff = this.$vc.getCurrentStaffInfo();
      if (orgId == null) {
        orgId = currentStaff.orgId;
      }
      const params = {
        regionCode: this.$vc.getCurrentRegion().code,
        departmentCode: orgId,
        // jobLevel: 1
        isOnTheJob: true,
      };
      let paramsStr = [];
      for (const key in params) {
        paramsStr.push(key + "=" + params[key]);
      }
      return this.$fly.post(queryDepartmentStaffUrl, params).then((res) => {
        this.staffList = res.data;
        return res.data;
      });
    },
    // 确认转移合同
    confirmTransferContract() {
      const {contractId} = this.transferContractModal;
      this.$fly
          .post(transferContractUrl, {
            contractId,
            operatorId: this.$vc.getCurrentStaffInfo().userId,
            transferTo: this.transferToId,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.transferContractModal.visible = false;
            this.$vc.toast("转移成功");
            this.getContractList();
          });
    },
    /**
     * 显示转移合同弹窗
     * @param {Number} id 合同id
     *  */
    showTransferContractModal(id) {
      this.transferContractModal = {
        visible: true,
        contractId: id,
      };
    },
    /**
     * 取消合同
     * @param {Number} contractId 合同id
     *  */
    cancelContract(contractId) {
      // this.terminateOrCancelContractModal.visible = true;
      // this.terminateOrCancelContractModal.title = "确定删除合同?";
      // this.terminateOrCancelContractModal.onOk = () => {
      //   this.terminateOrcancelContractRequest(
      //     contractId,
      //     ContractOperationType.CANCEL
      //   );
      //   this.terminateOrCancelContractModal.visible = false;
      // };

      this.$CSDialog.confirm({
        title: "提示",
        message: "确定删除合同?",
        onConfirm: () => {
          this.terminateOrcancelContractRequest(
              contractId,
              ContractOperationType.CANCEL
          );
          // this.$vc.toast("删除成功");
          this.$CSDialog.instance.closeDialog();
        },
      });
    },
    /**
     * 终止合同
     * @param {Number} contractId 合同id
     *  */
    async terminateContract(contractId) {
      this.terminateOrCancelContractModal.visible = true;
      this.terminateOrCancelContractModal.title = "确定终止合同?";
      this.terminateOrCancelContractModal.onOk = () => {
        this.terminateOrcancelContractRequest(
            contractId,
            ContractOperationType.TERMINATE
        );
        this.terminateOrCancelContractModal.visible = false;
      };
      var termList = await this.getContractDetail(contractId);

      //合同
      this.$fly.get(terminationContractUrl, {id: contractId}).then((res) => {
        if (res.code != 0) {
          return;
        }
        console.log(res.data);
        this.terminationContractList = {...res.data, ...termList.data};
        console.log(this.terminationContractList);
      });
    },
    /**
     * 取消/终止合同请求
     * @param {Number} contractId 合同Id
     * @param {Number} operationType  请求类型
     *  */
    terminateOrcancelContractRequest(contractId, operationType) {
      const urlMapping = {
        [ContractOperationType.CANCEL]: cancelContractUrl,
        [ContractOperationType.TERMINATE]: terminateContractUrl,
      };
      this.$fly
          .post(urlMapping[operationType], {
            contractId,
            operatorId: this.$vc.getCurrentStaffInfo().userId,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.$vc.toast("操作成功");
            this.getContractList();
          });
    },
    /**
     * 弹窗显示/隐藏控制
     * @param {Boolean} isShow 是否显示
     *  */
    changeOperateContractModalShowStatus(isShow) {
      this.operateContractModal.visible = isShow;
    },
    // 获取合同列表
    getContractList(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly
          .post(queryContractListUrl, {
            operatorId: this.$vc.getCurrentStaffInfo().userId,
            keyword: this.queryParams.keyword,
            state: this.queryParams.state,
            pageNo,
            pageSize,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize: pageSize,
              });
            }
            this.contractList = res.data.datas;
          });
      // TODO:合同列表 后台数据库没数据
    },
  },
};
</script>
<style lang="stylus">
#contract-manage {
  .old-contract-info {
    padding: 30px;
    font-size: 24px;
    position: relative;
    margin-bottom: 20px;
    color: #000;
  }

  .old-contract-info span {
    vertical-align: middle;
    line-height: 1;
  }

  .old-contract-info .allow-click {
    margin-left: 10px;
  }

  .old-contract-info::after {
    position: absolute;
    bottom: 0;
    left: 30px;
    width: calc(100% - 60px);
    height: 1px;
    background: #dddddd;
    content: ' ';
  }

  /* 合同第二步样式 */

  .backgauge {
    margin-right: 50px;
  }

  /* .step-component {
      position: relative;
  } */
  /* 新增样式 */

  .col-sm-8 {
    width: 750px;
  }

  .mb-1.backgauge1 {
    margin-right: 5px;
    margin-top: 50px;
  }

  .step-component .el-input {
    font-size: 24px;
  }

  .step-component {
    width: 1090px;
    /* max-height: 430px; */
    overflow-y: auto;
    font-size: 24px;
  }

  .step-component .btn {
    font-size: 24px;
  }

  .step-component .glyphicon-remove-circle {
    color: #ff0000;
  }

  /* 浮动 */

  .step-component.leftFloat {
    /* 转换为行内块级元素 */
    display: inline-block;
    margin-right: 10px;
  }

  .col-sm-2 text-right {
    margin-right: 20px;
  }

  .custom-select {
    background-image: none;
  }

  .custom-select month {
    color: #999999;
  }

  select {
    color: #000 !important;
  }

  .ibox-content {
    margin: 0 auto !important;
    padding: 20px;
  }

  .margin_ {
    margin: 0;
  }

  .padding_ {
    padding-top: 0;
  }

  .box-table .ibox-content {
    padding: 0 !important;
  }

  .btn-download {
    padding: 0;
  }

  .mr_3 {
    /* width: 60px; */
    color: #fff;
    /* height: 34.57px; */
    background: #ff9f00;
    box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
    border-radius: 3px;
    margin-right: 30px;
  }

  /* .nav_bar.el-select {
    position: relative;
    top: -7px;
    left: -14px;
    width: 243px;
    padding-left: 15px;
  } */

  .el-select .el-input__inner {
    position: relative;
    top: -7px;
    left: -13px;
    width: 242px;
  }

  .el-select .el-input .el-select__caret {
    margin-top: -7px;
  }

  .padding_none {
    margin-bottom: 0;
  }

  .cs-style .ibox-table-wrap .ibox-title {
    min-height: 64px;
  }

  .el-icon-arrow-up {
    position: relative;
    top: 0;
    left: 30px;
  }

  .transfer .el-icon-arrow-down {
    position: relative;
    top: 4px;
    left: 135px;
    font-size: 20px;
  }

  .cancel {
    width: 80px;
    height: 40px;
    background: #ff9f00;
    box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
    border-radius: 6px;
    color: #fff;
    border: 0;
    font-size: 18px;
    margin-right: 30px;
  }

  .confirm {
    width: 80px;
    height: 40px;
    background: #1ab394;
    box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
    border-radius: 6px;
    color: #fff;
    border: 0;
    font-size: 18px;
  }

  #component .el-dialog__footer {
    text-align: center;
  }

  .container-so {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    opacity: 1;
    margin: 10px auto;
    border: 1px solid #cccccc;
    border-radius: 10px;
    width: 390px;
    padding: 15px;
  }

  .container-item {
    display: flex;
    margin-bottom: 20px;
  }

  .container-item .container-itemr {
    min-width: 220px;
    text-align: right;
    margin-right: 40px;
  }

  .icon-size {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }

  .td_two {
    position: relative;
  }

  .change_contract {
    display: inline-block;
    margin-bottom: 16px;
    margin-left: 5px;
  }

  .container-timef {
    text-align: left;

  }
}
</style>
 
